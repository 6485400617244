<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ action === 'Save' ? 'Add' : 'Update' }} Station</span>
        </v-card-title>
        <v-form
          ref="form"
          method="post"
          action="/"
          lazy-validation
          @submit.prevent="saveOrUpdate()"
        >
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="form.name"
                    label="Station name"
                    placeholder="Some cool name"
                    :rules="[
                      v => !!v || 'Station name is required',
                    ]"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('name')"
                    class="validation-error"
                  >{{
                    form.errors.get('name')
                  }}</small>
                  <v-text-field
                    v-model="form.location"
                    label="Station location"
                    placeholder="Location where the station resides"
                    :rules="[
                      v => !!v || 'Location name is required',
                    ]"
                    required
                  ></v-text-field>
                  <small
                    v-show="form.errors.has('location')"
                    class="validation-error"
                  >{{
                    form.errors.get('location')
                  }}</small>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="closeDialog()"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :loading="form.busy"
            >
              {{ action }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Form from 'vform'
import { mapActions } from 'vuex'

export default {
  props: {
    showDialog: {
      type: Boolean,
    },
    station: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    form: new Form({
      name: '',
      location: '',
      paybill_number: '',
      id: '',
    }),
    action: 'Save',
  }),
  watch: {
    station() {
      if (Object.keys(this.station).length > 0) {
        this.form.update(this.station)
        this.action = 'Update'
      } else {
        this.action = 'Save'
      }
    },
  },
  methods: {
    ...mapActions(['refreshStations']),
    closeDialog() {
      this.$emit('close')
      this.$refs.form.reset()
    },
    saveOrUpdate() {
      const isFormValid = this.$refs.form.validate()
      if (isFormValid) {
        if (this.action === 'Save') {
          this.saveStation()
        } else {
          this.updateStation()
        }
      }
    },
    saveStation() {
      this.form
        .post('meter-stations')
        .then(() => {
          this.$notification.success('Station added successfully')
          this.$emit('close')
          this.$emit('station-added')
          this.refreshStations(true)
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
    updateStation() {
      this.form
        .put(`meter-stations/${this.form.id}`)
        .then(() => {
          this.$notification.success('Station updated successfully')
          this.$emit('close')
          this.$emit('station-added')
          this.refreshStations(true)
          this.$refs.form.reset()
        })
        .catch(error => {
          this.$notification.error(error.response.data.message)
        })
    },
  },
}
</script>
